<template>
  <v-row>
    <v-col cols="12">
      <Socios />
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Socios from './tablas/Socios.vue'

export default {
  components: {
    Socios,
  },
}
</script>
