<template>
  <div>
    <v-card-title>{{ `${$t('menu.configuration')} / ${$t('landing.event.socios')}` }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="$router.push({ name: 'events-list' })"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('landing.event.socios') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              width="10%"
            >
              LOGO
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.description') }}
            </th>
            <th
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <v-row>
                <v-col cols="6">
                  <v-avatar
                    v-if="iten.isotipo === null"
                    color="primary"
                    size="48"
                  >
                    <span class="white--text text-h5">{{ iten.name }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else
                    class="profile"
                    size="60"
                    tile
                  >
                    <v-img
                      :src="urlS3 + iten.logo"
                      :alt="iten.name"
                      contain
                    ></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </td>
            <td>
              {{ iten.title }}
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ iten.description | truncate(30) }}</span>
                </template>
                <span>{{ iten.description }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <!--<v-tooltip
                v-if="permisos.includes('nom_aerolines:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>-->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR FORM -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('landing.event.socios') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="item.title"
                :label="$t('lbl.name')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="item.description"
                :label="`${$t('lbl.description')}`"
                outlined
                dense
                rows="2"
                hide-details="auto"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          {{ $t('msg.deleteItem', { n: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      logo: null,
      itemId: null,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      user: {},
    }
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations(['updateContactsAfiliados']),
    async fetchDataFromEndpoints() {
      const jsonLoad = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
      }
      const jsonAll = {
        per_page: 1000000,
      }
      try {
        const [
          resLoad,
          resItems,
        ] = await Promise.all([
          this.axios
            .post('socios/list', jsonLoad, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('socios/list', jsonAll, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])
        // eslint-disable-next-line no-empty
        if (resLoad.data.success === false) { } else {
          this.items = resLoad.data.data
          this.pagination.current = resLoad.data.meta.current_page
          this.pagination.total = resLoad.data.meta.last_page

          this.totalF = resLoad.data.meta.total
        }

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.totalItems = resItems.data.meta.total
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    load() {
      const jsonLoad = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('socios/list', jsonLoad, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {} else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      this.item = {}
      this.itemId = null
      this.isDialogVisible = true
    },
    editItem(item) {
      this.item = item
      this.itemId = item.id
      this.isDialogVisible = true
    },
    save() {
      if (this.item.title) {
        this.loading = true
        const formData = new FormData()
        if (this.logo) {
          formData.append('logo', this.logo)
        }

        formData.append('data', JSON.stringify(this.item))

        if (this.itemId === null) {
          if (this.logo) {
            this.axios
              .post('socios/store', formData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  } else if (response.data.data.status === 402) {
                    this.$toast.error(this.$t('landing.event.existSocio', { n: this.item.title }))
                  }
                } else {
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => this.reseat())
          } else {
            this.loading = false
            this.$toast.error(this.$t('msg.datasRequiere'))
          }
        } else {
          this.axios
            .post('socios/update', formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`nom_aerolines/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.logo = null
      this.fetchDataFromEndpoints()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
